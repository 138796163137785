.containerBox {
    position: relative !important;
}

.bgImage {
position: absolute !important;
z-index: -1 !important;
left: 0 !important;
width: 100% !important;
height: 100% !important;
object-fit: cover !important;
object-position: center
}
.background {
    border-radius: 32px !important;
    background: #fff !important;
    margin: 4% !important;
    display: flex !important;
}

@media (max-width : 600px) {
    .background {
        display: contents !important;
    }
}

.leftSide {
padding: 60px 50px !important;
}
.rightSide {
height: 730px !important;
padding: 40px !important;
border-radius: 32px !important;
background-color: #fdd001 !important;
position: relative !important;
}
.signup {
margin-bottom: 40px !important;
font-family: "SofiaPro", sans-serif !important;
font-size: 22px !important;
color: #1e1f20 !important;
}
.VerifyOTP {
font-family: "SofiaPro", sans-serif !important;
font-size: 15px !important;
color: #1e1f20 !important;
margin-bottom: 10px !important;
}
.sentInfo {
width: 320px !important;
max-width: 100% !important;
}
.weHaveSentAnOne {
margin: 7px 0 0 !important;
font-family: "SofiaPro", sans-serif !important;
font-size: 14px !important;
color: #8f92a1 !important;
display: inline !important;
}
.OTP {
margin-top: 30px !important;
font-family: "SofiaPro", sans-serif !important;
font-size: 12px !important;
color: #1e1f20 !important;
margin-bottom: 10px !important;
}
.didntGetTheOTP {
font-family: "SofiaPro", sans-serif !important;
font-size: 14px !important;
color: #565656 !important;
display: inline !important;
padding-left: 0.5em !important;
cursor: pointer
}
.otpInputBox > div > div  {
    width: 48px !important;
    height: 48px !important;
    border: 1px solid rgb(143 146 161 / .4) !important;
}
.otpInputBox > div > div > input {
    color: "#8F92A1";
    font-size: "14px";
}

.rectangle {
width: 106px !important;
height: 32px !important;
margin: 0px 18px 0px 0px !important;
padding: 5px 10px !important;
border-radius: 4px !important;
background-color: #8f92a1 !important;
}
.label {
margin: 0 5px 0 0 !important;
font-family: "SFProDisplay", sans-serif !important;
font-size: 14px !important;
font-weight: 500 !important;
font-stretch: normal !important;
font-style: normal !important;
line-height: 1.43 !important;
letter-spacing: normal !important;
color: #ffffff !important;
text-transform: lowercase !important;
}
.userImg {
width: 14px !important;
height: 14px !important;
margin: 3px 0 3px 5px !important;
object-fit: contain !important;
}
.storeImg {
width: 14px !important;
height: 14px !important;
margin: 3px 0 3px 5px !important;
object-fit: contain !important;
}
.colorLogoTransparent {
width: 153px !important;
height: 47px !important;
object-fit: contain !important;
float: right !important;
}
.groupLogo {
width: 438px !important;
position: absolute !important;
bottom: 0px !important;
left: 37px !important;
}
.digitPasscod {
width: 162px !important;
height: 20px !important;
margin: 14px 175px 10px 0 !important;
font-family: "SofiaPro", sans-serif !important;
font-size: 12px !important;
font-weight: normal !important;
font-stretch: normal !important;
font-style: normal !important;
line-height: 1.67 !important;
letter-spacing: 1px !important;
color: #1e1f20 !important;
}
.signUpButton {
width: 100% !important;
height: 58px !important;
margin: 50px 0 20px 0px !important;
padding: 20px !important;
border-radius: 12px !important;
background-color: #32795f !important;
}
.signUpButtonTitle {
width: 35px !important;
height: 20px !important;
font-family: "SofiaPro", sans-serif !important;
font-size: 14px !important;
font-weight: normal !important;
font-stretch: normal !important;
font-style: normal !important;
line-height: 1.43 !important;
letter-spacing: normal !important;
text-align: center !important;
color: #ffffff !important;
}
.alreadyHaveAnAcco {
font-family: "SofiaPro", sans-serif !important;
font-size: 14px !important;
font-weight: normal !important;
font-stretch: normal !important;
font-style: normal !important;
line-height: normal !important;
letter-spacing: -0.35px !important;
color: #565656 !important;
}
.otpField {
opacity: 0.2 !important;
padding: 1px 16px 3px !important;
border-radius: 4px !important;
}
.errorLabel {
color: red !important;
}