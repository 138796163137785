.container-box {
  position: relative;
  background-image: url("../../assets/bg.jpg") !important;
	padding: 0 15px;
  height: 100%;
}

@font-face {
    font-family: sofiaPro;
    src: url('../fonts/Sofia Pro Regular Az.otf');
}

#root input{
  font-family: sofiaPro !important;
}
.bg-auth {
  background: #fff;
  max-width: 1110px;
  border-radius: 32px;
  margin: 20px 0;
}

.left-side {
	padding: 60px 50px;
	position: relative;
}
.bg-auth .main-head {
  margin-top: 12px;
  margin-bottom: 0px!important;
  
}
.enter-log {
  margin-top: 10px!important;
}
.signup-alx .left-side{
margin-top:10px;
}
.btn-box.sign-box {
  bottom: 0px!important;
}
.signup-alx .pb-150 {
  padding-bottom: 13px !important;
}
.btn-box button{
/* height: 45px; */
padding: 10px;
}

.btn-box {
  position: relative;
  bottom: -20px!important;
  width: 100%!important;
}
.left-side {
  padding: 0px 50px!important;
  
}
.bg-auth .pb-230 {
  padding-bottom: 0px !important;
}
.right-side {
  
  min-height: 569px!important;
}
.mt-70.log-ico{
margin-top:60px!important;
}
.left-side .bg-auth .label-name{
margin-top:10px;
}
.right-side .bt-80 {
  bottom: 0px !important;
}
.group-logo {
  bottom:0px!important;
  width: 85%;
}

.signup-back span {
  display: inline-block;
  position: relative;
  right: 8px;
  bottom: 4px;
}
.signup-back {
  margin: 10px 0px;
}
.signup-back p {
  display: inline-block;
}
.sign-adjest .pb-150 {
  padding-bottom: 0px!important;
}
.sign-adjest .label-name {
  position: relative;
  bottom: 7px;
  margin-bottom: 0px!important;
  }
  .sign-adjest .phoneNumberCont{
    margin-top:-10px;
  }
  .sign-adjest{
  
  margin: 24px 0px!important;
}
 .sign-adjest .change-passcode input {
  width: 30px!important;
  height: 30px!important;
}
  .sign-adjest .right-side {
  min-height: auto!important;
}
  .sign-adjest .mt-25 {
  margin-top: 0px !important;
}
.login-adjest .mt-25 {
  margin-top: 22px !important;
}


.bg-auth .main-head {
	margin-bottom: 25px;
  font-size: 22px;
  color: #1e1f20;
}
.main-content{
    font-size: 18px;
    color: #8f92a1;
}
.bg-auth .label-name {
	color: #1e1f20;
  margin-bottom: 5px;
  font-size: 12px;
  width: 100%;
}
.auth-btn:hover{
    background: #32795f !important;
}
.bg-auth .auth-btn {
  background: #8f92a1 ;
	margin-bottom: 22px;
  padding: 4px 10px;
  height: 30px;
  font-size: 14px;
  border-radius: 4px;
  text-transform: initial;
  color: #fff;
  margin-right: 15px;
}

.light {
	background-color: #32795f !important;
}

.bg-auth .auth-btn img {
	width: 14px;
  height: 14px;
  margin: 3px 0 3px 5px;
  object-fit: contain;
}

.user-mobile {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.user-mobile > div > div > div {
  height: 48px;
  border: 2px solid #e5e5e5;
  border-right: 0;
  border-radius: 4px 0 0 4px;
}

.user-mobile > div > span {
  background: transparent;
}

.user-mobile .number-field {
  margin: 0;
  border-radius: 0 4px 4px 0;
  border: 2px solid #e5e5e5;
  flex: 1;
  height: 44px;
  position: relative !important
}

.user-mobile .number-field input {
  height: 44px;
  padding: 10px;
  box-sizing: border-box;
  color: #8F92A1;
  font-size: 14px;
}
.text_underline{
    text-decoration: underline;
    cursor: pointer;
}
.user-mobile .number-field fieldset {
	border: 0;
}

.phoneNumberCont {
  width:"100%";
  display: 'flex';
  align-items:"flex-start",
}
.css-yk16xz-control {
  height: 45px !important;
}
.css-1pahdxg-control {
	height: 45px !important;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl input.MuiInputBase-input.MuiOutlinedInput-input {
  height: 40px;
  padding: 2px 10px;
  width: 335px;
}
.MuiOutlinedInput-root {
  position: relative;
  border-radius: 4px;
  top: -3px;
  right: 0px;
}
.inputNumberBox {
  padding:"5px 10px" !important;
  width:"calc(100% - 120px)";
  height:38;
  border:"2px solid rgba(143, 146, 161, 0.4)";
  color:"#1e1f20";
  border-radius:4;
  background-color:"transparent";
}

.login_number_cont{
  width: 100%;
  display: flex;
  align-items: center;
}

.text-field {
	border-radius: 4px;
  border: 2px solid #e5e5e5 !important;
  flex: 1;
  height: 44px;
  position: relative !important
}

.css-yk16xz-control:hover {
	border-color: #32795f !important;
}

.css-vy2pk9-control {
	box-shadow: 0 0 0 1px #32795f !important;
}

.css-yk16xz-control:hover {
	box-shadow: 0 0 0 1px #32795f !important;
}

.text-field label {
	margin-top: -6px;
	background: #fff;
	padding: 0 10px;
}

.MuiFormLabel-root.Mui-focused {
	margin-top: 0;
}

.text-field input {
	height: 44px;
  padding: 10px;
  box-sizing: border-box;
  color: #8F92A1;
  font-size: 14px;
}

.text-field fieldset {
	border: 0;
}

.error-label {
  color: red;
}

.why-email {
  color: #8f92a1;
  font-size: 14px;
  display: block;
  cursor: pointer;
  text-align: right;
  margin-bottom: 0;
}

.otp-input-box > div > div {
  width: 48px;
  height: 48px;
  border: 1px solid rgb(143 146 161 / .4);
}


.otp-input-box > div > div > input {
  color: #8F92A1;
  font-size: 14px;
}

.otp-input-box > div > input {
  color: #8F92A1 !important;
  font-size: 14px;
}

.pass-code-field {
  /* margin: 0 8px 0 0;
  padding: 13px 18px 15px 20px; */
  border-radius: 4px;
  /* margin-left: 2px; */
}

.mt-25 {
	margin-top: 25px !important;
}

.check-box-field {
  display: flex;
  margin: 20px 0;
}

.check-box-field > div > div {
  border: 1px solid #ddd !important;
}

.agree-terms {
  color: #8f92a1;
  margin: 0 0 0 5px;
  font-size: 14px;
}

.btn-box {
	position: relative !important;
  left: 50%;
  transform: translatex(-50%);
  bottom: 40px;
	width: calc(100% - 100px);
}

.btn-box-forgotPassCode {
    margin: 70px auto;
    /*width:90%;*/

}

.site-lg-btn {
	width: 100%;
  height: 58px;
  margin: 0 0 10px 0 !important;
  border-radius: 12px !important;
  background: #32795f !important;
  font-size: 14px !important;
  color: #ffffff !important;
}

.have-acc {
  color: #565656;
  display: inline-block;
  margin: 17px 0 0;
  font-size: 14px;
  vertical-align: top;
  cursor: pointer;
}

.left-side .login-btn {
  margin: 12px 0 0 5px;
  background: #32795f !important;
  padding: 4px 20px;
  font-size: 12px;
  border-radius: 4px;
  text-transform: initial;
  color: #fff;
  /* height: 26px; */
}

.left-side .login-btn img {
	margin-right: 5px;
}

.right-side {
	padding: 40px;
	border-radius: 32px;
	background: #fdd001;
	position: relative;
	min-height: 770px;
}

.site-logo {
  float: right;
  cursor: pointer;
}

.group-logo {
  position: absolute;
  bottom: 0px;
  left: 27px;
  max-width: 100%;
}

.enter-your-phone {
  color: #8f92a1;
  margin-bottom: 10px !important;
  font-size: 14px !important;
  width: 260px;
}

.site-wht-btn {
	width: 100%;
	height: 58px;
	margin: 15px 0 10px 0 !important;
	border-radius: 12px !important;
	background: #fff !important;
	font-size: 14px !important;
	color: #1e1f20 !important;
	border: solid 2px rgb(143 146 161 / .2) !important;
  box-shadow: none !important;
}

.right-icons {
  width: 60%;
  display: flex;
  text-align: center;
  margin: 0px auto 10px;
}

.right-icons img {
  margin: 0 10px;
  width: 60px;
  cursor: pointer;
  height: 60px;
}

.didnt-get-otp {
  font-size: 14px !important;
  color: #565656;
  display: inline;
}

.mt-0 {
	margin-top: 0 !important;
}

.mt-70 {
	margin-top: 120px !important;
}

.bt-80 {
	bottom: 80px !important;
}

.pb-20 {
	padding-bottom: 20px !important;
}

.mb-70 {
	margin-bottom: 70px !important;
}

.pb-150 {
	padding-bottom: 20px !important;
}

.pb-230 {
	padding-bottom: 230px !important;
}

.mt-40 {
	margin-top: 40px !important;
}
.login-back {
  padding: 10px 0px;
}

.login-back span {
  position: relative;
  bottom: 3px;
  display: inline-block;
}
.login-back span img {
height: 24px;
padding-right: 18px;
cursor: pointer;
}
.login-back p{
display: inline-block;
}

.signup-back span {
  position: relative;
  bottom: 3px;
  display: inline-block;
}
.signup-back span img {
height: 24px;
padding-right: 18px;
cursor: pointer;
}
.signup-back p{
display: inline-block;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media screen and (max-width: 1200px) {
	.group-logo {
		max-width: calc(100% - 60px);
	}

	.right-side {
		min-height: 730px;
	}
}

@media screen and (max-width: 992px) {
	.container-box {
		/* height: 100%; */
	}

	.left-side {
		padding: 60px 40px;
	}

	.otp-input-box > div > div {
		width: 40px;
    height: 40px;
	}
}

@media screen and (max-width: 480px) {

  .bg-auth .main-head{
    margin-top: 0px;
  }

  .login-adjest .mt-25 {
    margin-top: 10px !important;
  }

  .bg-auth .label-name {
    margin-bottom: 2px;
  }

  .mt-25 {
    margin-top: 10px !important;
  }

	.left-side {
		padding: 60px 40px 150px;
	}

	.bg-auth {
		margin: 0px 0;
	}

  .login-adjest{
    margin: 0px 0;
  }

	.bg-auth .auth-btn {
		margin-right: 8px;
    margin-bottom: 10px;
	}

	.group-logo {
		max-width: calc(100% - 60px);
	}

	.right-side {
		min-height: 600px;
	}
	
	.pb-xs-180 {
		padding-bottom: 180px !important;
	}
}
@media screen and (max-width: 600px) {
  .container-box {
    height: auto;
  }

    .userSettings_mobile_cont{
        margin-top: 30px !important;
    }
}
.right-alert-img{
    position: relative;
}
.icon_cont{
  width: 40%;
  position: relative;
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  align-items: center;
}

.icon_name{
  position: absolute;
  margin: 1px 0;
  font-size: 12px;
  background-color: #1e1f20;
  border-radius: 8px;
  color: white;
  padding: 5px;
  font-family: sofiaPro;
  min-width: 120px;
  transition: all 0.1s ease-in;
}
.icon_name{
  opacity: 0;
  bottom: 50%;
}
.icon_cont:hover .icon_name{
 opacity: 1;
  bottom: 100%;
}

/* @media screen and (max-width: 2000px){
  .login-adjest{
    margin:44px 0px 43px 0px;
  }
  .sign-adjest {
    margin:48px 0px 47px 0px;
  }
}
@media screen and (max-width: 1300px){
  .login-adjest{
    margin:20px 0px 20px 0px;
  }
  .sign-adjest {
    margin:20px 0px 20px 0px;
  }
} */

@media screen and (max-width: 768px){
  .login-adjest .right-side .bt-80 {
      bottom: 180px !important;
  }
  .login-adjest .mt-70.log-ico {
      margin-top: 230px!important;
  }
  }
  @media screen and (max-width: 540px){
  
  .login-adjest .left-side {
      padding-bottom: 50px!important;
  }
  .signup-back span img {
    
    padding-right: 0px;
    
}
  .zsiq_floatmain.zsiq_theme1.siq_bR{
    bottom:40px!important;
  }
  .sign-adjest .log-ico{
    right:14px;
  }
  .sign-adjest .left-side {
      padding: 0px 40px!important;
  }
  .sign-adjest .right-side .bt-80 {
      bottom: 90px !important;
  }
  .sign-adjest .log-ico {
      top: 70px;
  }
  .sign-adjest .right-icons {
      width: 100%;
      }
  .sign-adjest .right-side {
      min-height: 700px!important;
  }
  .sign-adjest .pb-150 {
      padding-bottom: 30px!important;
  }
  .login-adjest .mt-70.log-ico {
      margin-top: 150px!important;
  }
  .login-adjest .right-side .bt-80 {
      bottom: 90px !important;
  }
  .login-adjest .right-side {
      min-height: 700px!important;
  }
  .login-adjest .right-icons {
      width: 100%!important;
      }
      .login-adjest .group-logo {
     
      width: 100%;
  }
}
@media screen and (max-width: 320px){

  .login-adjest .bg-auth .pb-230 {
      padding-bottom: 100px !important;
  }
  .bg-auth .auth-btn{
    width: max-content !important;
    }

  .agree-terms{
    font-size: 13px;
  }
  .login-adjest .left-side {
      padding: 50px 35px!important;
  }
  .login-adjest .mt-70.log-ico {
      margin-top: 150px!important;
  }
  .login-adjest .right-side .bt-80 {
      bottom: 150px !important;
  }
  .login-adjest .right-side {
      min-height: 700px!important;
  }
  .login-adjest .bg-auth .auth-btn{
    padding: 4px 8px;
  }
  .login-adjest .log-ico{
    right:12px;
  }
  .login-adjest .enter-your-phone{
    width:auto!important;
  }
  .login-adjest .right-icons {
      width: 100%!important;
      }
      .login-adjest .group-logo {
      
      width: 100%;
  }
  }

@media screen and (max-width: 280px){

  .login-adjest .bg-auth .pb-230 {
      padding-bottom: 100px !important;
  }
  .sign-adjest .left-side {
      padding: 0px 15px!important;
  }
  .log-num .MuiFormControl-root {
      width: 93%!important;
  }
  .zsiq_floatmain.zsiq_theme1.siq_bR{
    right: 0;
  }
  .login-adjest .left-side {
      padding: 50px 13px!important;
  }
  .login-adjest .mt-70.log-ico {
      margin-top: 150px!important;
  }
  .login-adjest .right-side .bt-80 {
      bottom: 150px !important;
  }
  .login-adjest .right-side {
      min-height: 700px!important;
  }
  .login-adjest .bg-auth .auth-btn{
    padding: 4px 8px;
  }
  .login-adjest .log-ico{
    right:35px;
  }
  .sign-adjest .log-ico{
    right:30px;
  }
  .login-adjest .enter-your-phone{
    width:auto!important;
  }
  .login-adjest .right-icons {
      width: 100%!important;
      }
      .login-adjest .group-logo {
      
      width: 100%;
  }
      
  }

  .state-btn{
    border: 1px solid #32795f !important;
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
    cursor: pointer;
  }

  .selected-state{
    background-color: #32795f !important;
    color: white;
  }