@font-face {
    font-family: 'Segoe UI';
    src: url('./Segoe UI Bold.woff') format('woff2'),
    url('./Segoe UI Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
    
@font-face {
    font-family: 'Segoe UI';
    src: url('./Segoe UI.woff') format('woff2'),
        url('./Segoe UI.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}