.CookieConsent{
  position:relative;
}
.contact-bx-size .MuiGrid-container {
    display: inline-block;
}
.service-vid-align .video-left font {
    color: #484848;
}
span.contact-ico-txt {
    margin: 10px 6px;
    display: inline-block;
}
span.contact-ico-txt i {
    font-size: 20px;
}
.CookieConsent a:focus, a:hover{
  color: #337AB7;
  text-decoration: none;
}
a.contact-social-icon-align {
    position: relative;
    right: 6px;
}
.about-content-size{
  margin: 25px;
  width: 90%;
    margin-left: auto;
    margin-right: auto;
}
.about-logo{
background-image: url("../assets/lg.png");
    background-size: cover;
    width: 308px;
    height: 232px;
    background-position: center;
}
.about-desc p {
    font-family: 'Segoe UI';
    line-height: 24px;
    text-align: justify;
}
.about-desc ul li {
    font-family: 'Segoe UI';
    line-height: 24px;
    text-align: justify;
}
.service-vid-align .video {
  position: relative;
  top: 53px;
}
 .PrivacyPolicy-privacyContent-2{
  margin-bottom: 40px!important;
}
/* .MuiGrid-root{
  width:100%;
} */
.header-nav-right .MuiButton-text {
  width: 110px;
}
.contact-size-btx button {
    font-size: 20px;
}
.nv-top-cover span {
    font-size: 17px;
}
.service-detail-get .gt-txt p {
   
    right: 50px!important;
}
.service-detail-get .gt-txt h2 {
    
    margin: 50px 30px 0px 30px;
   
}
p.hideShadow {
    box-shadow: none;
}
.terms {
    margin-bottom: 40px;
}
.download-app-align{
  background: linear-gradient(258deg, #359b76 97%, #32795f);
    box-shadow: 0 10px 20px 0 rgba(50, 121, 95, 0.2);
    border-radius: 5px!important; 
    padding:4px 8px 5px 8px;  
}
.service-detail-get {
    
    margin: -100px 11px 50px -11px;
    display: inline-block;
}
.service-page .print-section {
    text-align: center;
    margin: 50px 0px;
}
.service-detail-get .gtm-txt a {
    padding: 18px 20px;
}
.testiImg {
  height: 60px;
  width: 60px;
  border-radius: 30px;
}
.Header_Dropdown_Cont span{
  font-size: 17px;
}
.contact-bx-size {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
}
.contact-bx-size ul.list.contx {
    margin: 0;
    padding: 0;
}
.contact-bx-size ul.list.contx a {
    color: #359b76;
    font-family: 'Segoe UI';
    line-height: 33px;
    text-decoration: none;
}
.siq_bR {
    bottom: 40px!important;
    right: 10px;
}
.faq-align .MuiAccordionSummary-content {
    margin: 0px 0;
    }
.faq-align p.MuiTypography-body1{
 font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    color:#484848;
    font-family: 'Segoe UI';
    padding-left: 6px;
}
.privacyPolicyUnderLine {
  text-decoration: underline
}
ul.print-list li img {
    width: 78px;
    height: 66px;
}
.faq-align .Mui-expanded .filx{
  background: none;
    padding: 0px;
    border-radius: none;
}
.faq-align .Mui-expanded p.MuiTypography-body1{
  padding-left: 20px!important;
}
.filx{
  background: url("../assets/fq1.png");
   padding: 15px;
    border-radius: 11px;
    position: absolute;
    width: 305px;
    background-size: cover;
    left: 0;
    background-position: center;
    height: 53px;
    top: 0;
}
.terms{
  margin-top:120px;
}
p.salesContent {
    width: 212px;
    text-align: justify;
    
}
.grid-align {
    width: 95%;
}

.filx1{
  background: url("../assets/fq1.png");
   padding: 15px;
    border-radius: 11px;
    position: absolute;
    width: 305px;
    background-size: cover;
    left: 0;
    background-position: center;
    height: 53px;
    top: 0;
}
.privacy {
    margin-top: 120px;
}
.service-page ul.print-list span {
    font-size: 24px;
    }
    .service-page ul.print-list li{
      width:40%;
    }
.filx2{
  background: url("../assets/fq1.png");
   padding: 15px;
    border-radius: 11px;
    position: absolute;
    width: 305px;
    background-size: cover;
    left: 0;
    background-position: center;
    height: 53px;
    top: 0;
}
.filx3{
  background: url("../assets/fq1.png");
   padding: 15px;
    border-radius: 11px;
    position: absolute;
    width: 305px;
    background-size: cover;
    left: 0;
    background-position: center;
    height: 53px;
    top: 0;
}
.header-sb img.Header-navDownArrowImg-60 {
    display: none;
}
.ck1 {
    margin-bottom: 0px!important;
}
.tabs-view-fix {
    margin-bottom: 0px;
}
.gmn a{
  font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    font-family: 'Segoe UI';
}
.nav-top-middle .mega-menu-align {
    padding: 25px 0px 25px 0px;
}
.Header_sub_menu span img{
  display: none;
}
.tabs-view-fix .react-tabs__tab-panel {
    
    height: 550px;
}
.tabs-view-fix .react-tabs__tab {
    top:23px;
    margin: 23px 5px 85px 5px;
    
}
.tabs-view-fix .panel-content h3 {
    
    line-height: 22px!important;
}
.Header_Dropdown_Cont span{
  font-size: 17px;
}
.Header_sub_menu2 {
    display: none!important;
}
p.read {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
}
.header-sb h3{
  position: relative;
    bottom: 90px;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color:#626060;
}
.service-prt .gtm-txt a {
    
    padding: 18px 18px;
    }
    .service-print-detail .ndx {
    position: relative;
    top: 270px;
    margin-top: -140px;
}
.service-print-detail .img-cntr img {
    width: 100%;
}
.gtm-txt a {
    
    padding: 18px 27px;
    }
.header-sb h2{
  font-size: 18px;
    font-weight: 600;
    color: #4d4c4c;
    position: relative;
    bottom: 50px;
    margin-top: 160px;
}
.service-detail-tbs .panel-content{
  top:0;
}
.top-nav-align{
  position: fixed;
}
.navBarTopMargin {
    height: 100px;   
}
 p.header-line-qte{
     width: 90%!important;
    margin-left: auto;
    margin-right: auto; 
    text-align: center;  
  }
.third-menu {
    border-right: none!important;
    position: relative;
    top:18px;
    display: inline-block!important;
}
p.menu-title {
    position: relative;
    top: -30px;
    font-size: 18px;
    font-weight: 600;
    left: 20px;
}
.arrow-menu{
  position: relative;
    top: 2px;
    left: 3px;
}
.cd-pt {
    position: relative;
    top: 37px;
}
.data-pvt{
  padding: 20px 10px!important;
}
.data-pvt1{
  padding: 20px 42px!important;
}
.header-sb{
  grid-template-columns: repeat(3, 1fr);
  height: 500px;
  width:830px;
margin-top: 0px;
  flex-direction:row;
  padding-top: 60px;
  border-radius:0;
}
.header-sb p{
  position: relative;
    bottom: 90px;
    font-size: 15px;
    color: #5f5b5b;
}
.sb1{
  height: 350px;
}
.align-2{
  position: relative;
  top:0px;
  display: inline-block!important;
}
.menu-ico{
  background-image: url("../assets/public_queue.png");
   width: 47px;
    height: 41px;
    background-size: cover;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    bottom: 80px;
    margin-top:90px;
}
.service {
    background-image: url("../assets/service.jpg");
    background-size: cover;
    padding: 160px 0px 332px 0px;
}
.left-gle img{
  width:auto;
}
.menu-ico1{
  background-image: url("../assets/private_queue.png");
   width: 50px;
    height: 41px;
    background-size: cover;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    bottom: 80px;
    margin-top:90px;
}
.menu-ico2{
  background-image: url("../assets/hosted_queue.png");
   width: 54px;
    height: 41px;
    background-size: cover;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    bottom: 80px;
    margin-top:90px;
}
.sub-menu-alignx {
    display: inline-block!important;
    margin: 0px!important;
}
.menu-ico3{
  background-image: url("../assets/ideato_buisness.png");
   width: 54px;
    height: 41px;
    background-size: cover;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    bottom: 80px;
    margin-top:90px;
}
.btz ul li {
    margin: 6px;
}
.web-st img{
  height: 52px;
    position: relative;
    top: 1px;
}
.menu-ico4{
  background-image: url("../assets/ideato_challenge.png");
   width: 60px;
    height: 41px;
    background-size: cover;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    bottom: 80px;
    margin-top:90px;
}
.menu-ico5{
  background-image: url("../assets/ideato_challenge.png");
   width: 54px;
    height: 41px;
    background-size: cover;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    bottom: 80px;
    margin-top:90px;
}
.service-print-detail .print-section {
    
    margin: 0px 0px;
}
.read{
  text-align: center;
}
._temp-btnCont-37 .MuiButton-label{
font-size: 20px;
}
.nav-top-middle .Header-navMiddleMenuItem-62{
  font-size: 17px;
  display: inline-block;

}
.align-3{
  display: inline-block!important;
  margin: 0;
  border-right: 1px solid #ccc;
  position: relative;
  top:-10px;
}
.header-sb .sub-menu-alignx {
    border-right: 1px solid #ccc;
    margin-top:0px;
}
 .header-sb .align-2 {
    border-right: 1px solid #ccc;
    margin-top:0px;
}
.header-sb .third-menu {
    border-right: 1px solid #ccc;
    margin-top:0px;
}
.nav-top-middle .Header-navMiddleItem-61{
  font-size: 17px;
}
.custom-checkbox label:after{
padding-top:4px;
}
.service-detail-vector{
margin-bottom:0px!important;
}
.thumb-txt {
  position: relative;
  top: 15px;
}
.MuiSnackbarContent-message span{
font-size: 15px;
}
.MuiInputBase-input{
font-size: 12px!important;
}
ul.list-nav li {
display: inline-block;
margin: 10px 0px 10px 25px;
}
.social img {

margin: 2px;
transform: rotate(
270deg
);
}
.social  {
cursor: pointer;
}
ul.list-nav span {
padding-left: 9px;
}
.overly {
 
  padding: 0px 0px 0px 0px!important;
}
.bx{
padding-bottom: 100px!important;
}
.video.vid-ld video{
  width: 520px;
    height: 450px;
   object-fit: cover;       


}
.vid-ld {
position: relative;
    top: 60px;
    
    text-align: right;
    
}
.mult{
  position: relative;
    top: 44px;
}
.contx li{
display: flex;
}
.react-tabs__tab-panel--selected {
   
    border: 2px solid #32795f54;
    border-radius: 10px;
}
.MuiTypography-body1 {
  font-size: 16px;
}
.gmn{
  position: relative;
    top: 20px!important;
}
.react-tabs__tab-panel {
    position: absolute;
    top: 40px;
    height: 410px;
}


.react-tabs__tab-panel .panel-content h3{
  margin: 10px 0px;
  line-height: 18px;
}
.wt1{
  font-size: 20px;
  font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    font-family: 'Segoe UI';
}
.wt{
  font-size: 17px;
  color: #484848;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
}

.bx {
  display: inline-block;
  width: 100%;
  position: relative;
  top: 0px;
  background-image: url("../assets/curv.png");    
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 230px;
  padding-top: 50px;
}
.service-page .bx {
  display: inline-block;
  width: 100%;
  position: relative;
  top: 0px;
  background-image: url("../assets/001.png");
  background-repeat: no-repeat;
  background-size: auto;
  padding-bottom: 150px;
  padding-top: 70px;    
}
.service-detail-heading{
width: 100%;
}
.service-detail-tbs .react-tabs__tab img{
left:0;
width: 40px;
height: 30px;
}
.service-detail-tbs .react-tabs__tab p{
font-size: 18px;
}
.service-detail-heading font{
padding-right: 10px;
}
p.arrow span i {
font-size: 30px;
color: green;
}
.circle{
  background-image: url("../assets/elsp.png");
  width: 270px;
    height: 132px;
    position: absolute;
    left: 12px;
    background-size: cover;
    background-position: bottom;
    top: 0px;
}

i.fa.fa-long-arrow-left {
color: #0080006e;
}
.top-nav-align .MuiButton-root:hover {
  text-decoration: none;
  background-color: rgb(255 255 255);
}
p.arrow span{
position: relative;
   top: 80px;
  right: 35px;
padding-right: 10px;
cursor: pointer;
}
.cd-pt h4{
  padding:30px 0px 0px 0px!important;
}
p.qte {
  background: url("../assets/qte.png");
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  top: 43px;
  left: 10px;
  width: 190px;
  height: 60px;
}
p.arrow {
text-align: right;
}
.tst-qt{
width: 60%;
position: relative;
top: 140px;  
text-align: left;
font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
left:160px;
font-family: Helvetica;
}

.bx1 h2{
position: relative;
top: 135px;
text-align: left;
width: 50%;
font-weight: 600;
font-size: 40px;
left:160px;
}
.des1 {
display: block;
    position: relative;
    left: 75px;
    bottom: 31px;
    font-size: 14px;
    font-family: 'Segoe UI';
    margin-bottom: -3px;
}
.faq-align .MuiAccordion-rounded {
  border-radius: 5px;
}
p.arrow span {
  margin: 5px;
  position: relative;
  right: 150px;
}
span.arrow-left {
  background: url("../assets/arrow-right.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 35px;
  height: 35px;
  display: inline-block;
}
span.arrow-right {
  background: url("../assets/arrow-left.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 35px;
  height: 35px;
  display: inline-block;
}
.des{
display: block;
position: relative;
left: 0px;
bottom: 20px;
font-size: 14px;
}
.bx1 .row {
text-align: center;
position: relative;
top: 140px;
}
.bx1 {
display: inline-block;
width: 100%;
position: relative;
top: 0px;
background-image: url("../assets/test.png");
background-repeat: no-repeat;
padding-bottom: 260px;
background-size: cover;
margin-bottom: 50px;
}
.contx li span{
padding-right:10px;
}
audio, canvas, progress, video {
display: inline-block;
vertical-align: baseline;
border-radius: 20px;
}
.dot {
text-align: right;
position: relative;
bottom: 110px;
z-index: -1;
left: 25px;
}
.det .img-cntr {
margin-bottom: 70px;
}
.det p.line-px {
width: 60%!important;
}
.bottom-ft p {
color: #595555;
font-size: 15px;
}
.cookee-space {
    position: fixed;
    z-index: 99999;
    bottom: 0;
}
.ck{
  margin-bottom:0px!important;
}
.bt{
background: #d5d3d3;
margin-bottom:0px;
display: inline-block;
width:100%;
}
.custom-checkbox {
position: relative;
height: 30px;
width: 30px;
display: inline-block;
}
.custom-checkbox input {
appearance: none;
-o-appearance: none;
-ms-appearance: none;
-moz-appearance: none;
-webkit-appearance: none;
position: absolute;
display: block;
margin: 20px;
left: 0;
top: 0;
height: 100%;
width: 100%;
margin: 0;
opacity: 0;
}
.footer-bt-lft{
  width:100%!important;   
}
.ndx1 {
position: relative;
bottom: 150px;
}
.custom-checkbox label {
position: absolute;
left: 0;
top: 0;
width: 25px;
height: 25px;
display: block;
pointer-events: none;
}
.bottom-ft {
text-align: left;
padding: 20px 0px 0px 0px;
padding-left: 70px;

}
.check-img-align img {
  width: 121px;
  height: 91px;
}
.custom-checkbox label:after {
  content: ' ';
  background: #f0eded;
  border: 1px solid #747070;
border-radius: 24px;
display: block;
width: 100%;
height: 100%;
position: absolute;
transition: 'background .05s linear';
}
.custom-checkbox input:checked + label:after {
content: '\2713';
font-size: 15px;
background: rgb(50, 121, 95);
border: 1px solid #a3ce6a;
color: #FFFFFF;
text-indent: 2px;
line-height: 1;
box-shadow: none;
}

.custom-checkbox input:focus + label:after {
outline: 1px auto gray;
}

.jumbotron.text-center{
background-image: url("../assets/header-banner.jpg");      
background-size: cover;
padding:160px 0px 332px 0px;
}
.service-page .printing-services p {
width: 75%;
margin-left: auto;
margin-right: auto;

color: #615c5c;

}
.digital{
margin: 50px 0px 140px 0px;
width:100%;
}
.service-page ul.print-list li {

position: relative;
top: 12px;
}
.ndx{
position: relative;
top: 290px;
}
.det .txt {
position: relative;
top: 60px;
}
.det .print-section p {
width: 100%;
}
.det .gt-txt p {
width: 79%!important;
position: relative;
right: 28px;
}
.service-page .get{
top:0px;
width:96%;
right:10px;
}
.det .print-section h2 {
margin-bottom: 20px;
}
.det .print-section p {
width: 100%;
margin-left: auto;
margin-right: auto;
color: #615e5e;
font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
}
.react-tabs__tab-panel .panel-content h2{
  color:#484848;
  font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    font-family: 'Segoe UI';
}
.wt1 {
    
    color: #555;
}
.react-tabs__tab-panel .panel-content p{
  
  width: 93%;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    color: #484848;
    font-family: 'Segoe UI';
    
}
.wt {
   
    color: #555;
}
.react-tabs__tab p {
   font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    position: relative;
    top: 33px;
    color:#484848;
    width:100%;
    right:5px;
}
.react-tabs__tab--selected p{
  color:#fff;
}

.det .video-left p {

width: 100%;
}

.print-mg{
position: relative;
left: 15px;
width: 20%!important;
}


.faq-align .MuiAccordionSummary-root{
padding:0!important;
}
.faq-align .Mui-expanded .MuiIconButton-edgeEnd{
margin-right: 0px;
}
.faq-align .Mui-expanded .MuiAccordionSummary-root {
  
  border-bottom: 1px solid #32795f;
}
.dot1 {
  text-align: left;
  position: relative;
  bottom: 100px;
  z-index: -1;
  right: 25px;
}
.faq-align .Mui-expanded p.txt {
  font-size: 16px;
  color: #fff;
  font-family: 'Segoe UI';
  font-weight: 400!important;
}
.faq-align .Mui-expanded .MuiTypography-body1{
color:#fff;
}
.faq-align .Mui-expanded .MuiTypography-body1 font{
color:#fff;
}
.faq-align .Mui-expanded .MuiSvgIcon-root{
fill:white;
}
.faq-align .Mui-expanded{
background-color: #32795f!important;
}
.faq-align .MuiSvgIcon-root{
font-size: 30px!important;
color:green!important;
}
.mask {
  background: url("../assets/Mask.png");
 width: 50%;
  height: 500px;
  background-size: 100% 100%;
  position: relative;
  overflow: hidden;
  
}
.faq-align .MuiTypography-body1 font{
color:#f1bf06;
}
.faq-align .MuiAccordion-rounded {
  
  margin-bottom: 10px;
}
h2.fq {
  margin-top: -60px;
  margin-bottom: 60px;
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  position: relative;
  right:35px;
}
.top-nav-align .navMiddle {
  width: 60%;
  position: relative;
  left: 20px;
  
}
.download-app-align {
  width: 200px!important;
}
button.download-app-align span {
  color: #fff!important;
  border-right: none!important;
}
button.signup-btn-align span {
  border-right: none!important;
}
.header-line {
 
  color: #fff;
}
.top-nav-align .navBarContent {
  width: 85%;
  }
  .top-nav-align .Header-navMiddleItem-61 {
  color: rgba(109,109,109,1);
}
.top-nav-align .Header-navLoginBtn-63 {
  width: 95px!important;
  }
.header-nav-right .MuiButton-label {

  font-size: 18px;
  font-weight: 600;
  border-right: 1px solid #e6e5e5;
  padding-right: 12px;
  padding-left: 0px;
  color: #32795f;
}
.nav-top-middle i.fa.fa-angle-down {
  position: relative;
  left: 7px;
  top: 1px;
  color: rgb(50 126 99);
  font-size: 20px;
  font-weight: 600;
}
.service {
background-image: url('../assets/service.jpg')!important;
background-size: cover;
padding: 160px 0px 332px 0px;
}
.det .service {
background-image: url('../assets/det.jpg')!important;
background-size: cover;
padding: 160px 0px 332px 0px;
}
.faq-align{
  width: 65%!important;
  position: relative;
  margin-top: -440px;
  right: 45px;
}
.mask {
  width: 50%;
  height: 500px;
  background-size: 100% 100%;
  position: relative;
  overflow: hidden;
  left: 320px;
}
.txt h2 {
font-size: 16px;
text-align: center;
position: relative;
top: 20px;
font-family: 'Segoe UI';
}
.faq-align .Mui-expanded p.txt {
font-size: 16px;
color: #fff;
font-family: 'Segoe UI';
}
.faq-align .Mui-expanded .MuiTypography-body1{
color:#fff;
}
.faq-align .Mui-expanded .MuiTypography-body1 font{
color:#fff;
}
.faq-align .Mui-expanded .MuiSvgIcon-root{
fill:white;
}
.faq-align .Mui-expanded{
background-color: #32795f!important;
}
.faq-align .MuiSvgIcon-root{
font-size: 30px!important;
color: #32795f!important;
}

.faq-align .MuiTypography-body1 font{
color:#f1bf06;
}
.faq-align .MuiAccordion-rounded {
  
  margin-bottom: 10px;
}
.doorst{
  margin:20px 0px 20px 0px;
}
h2.fq {
  margin-top: -60px;
  margin-bottom: 60px;
  font-size: 40px;
  font-weight: 600;
  text-align: center;
}

.service-page ul.print-list li img {
width: auto;
}
.service-page ul.print-list{
text-align: left;
background: #f3f0f0;
border-bottom: none;
}
ul.list-service li span {
padding-right: 10px;
}
ul.list-service li span img {
width: 25px;
}
.Group_12438 {
width: 580px;
height: 327px;
left: 160px;
top: -75px;
overflow: visible;
position: relative;  
}
.footer-menu ul li {
list-style-type: none;
font-size: 15px;
font-weight: 600;
color: #505050;
line-height: 34px;
}
.footer-menu ul {
margin: 0;
padding: 0;
position: relative;
left: 0px;
}
.footer-menu h3 {
font-size: 14px;
text-align: center;
color: #000;
position: relative;
right:35px;
}
.Lets_get_started {
left: 30px;
top: 18px;
overflow: visible;
width: 140px;
white-space: nowrap;
text-align: left;
font-family: "Segoe UI";
font-style: normal;
font-weight: bold;
font-size: 18px;
color: rgba(255,255,255,1);
text-transform: capitalize;
}
.bottom-ft ul {
  margin: 0;
  padding: 0;
  display: flex;
}
.bottom-ft ul li {
  margin: 0px 15px;
  color: #615e5e;
  font-size: 15px;
  position: relative;
  bottom:5px;
}
.testimonial-inner-content h3{
text-align: center;
position: relative;
top: 20px;
font-weight: 600;
}
.Rectangle_1_zf {
filter: drop-shadow(0px 10px 20px rgba(50, 121, 95, 0.2));
overflow: visible;
width: 255px;
height: 120px;
left: 0px;
top: 0px;
}
span.toggle {
display: none;
}
.testimonial-inner-content p {
width: 58%;
margin-left: auto;
margin-right: auto;
font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
color: #6a6565;
position: relative;
top: 13px;
text-align: center;
}
.app-st{
  width:205px;
  display: inline-block;
}
.btz{
text-align: center;
}

.Group_12425 {
mix-blend-mode: normal;
width: 199px;
height: 60px;
left: 0px;
top: 267px;
overflow: visible;
}
.service .Group_12438{
top:0;
}
ul.list-service{
margin: 0;
padding:0;
}
ul.list-service li {
list-style-type: none;
margin: 20px 0px;
font-size: 20px;
color: #797777;
border-bottom: 1px solid #ccc;
padding-bottom: 20px;
}
.service-page .video-left p {

font-size: 17px;

}
.txts{
  width:1000px!important;
}
.testimonial-inner-content h2 {
text-align: center;
font-size: 50px;
font-weight: bold;
font-stretch: normal;
font-style: normal;
line-height: 1.34;
font-family: 'Segoe UI';
}
.testimonial-inner-content font{
color:#f8c811;
}
.print-section {
text-align: center;
margin: 150px 0px;
}
p.des {
text-align: center;
}
.print-section h2 {
color: #5d5b5b;
font-size: 50px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.34;
margin-bottom: 40px;
font-family: 'Segoe UI';

}
.print-section font{
color:#f8c811;
}
.logo-footer {
text-align: left;
position: relative;
bottom:25px;
}
.testimonial-align{
margin-bottom: 120px;
}
.A_SaaS_based_encrypted_printin {
left: 0px;
top: 164px;
line-height: 1.8;
margin-top: -8px;
text-align: left;
font-family: "Segoe UI";
font-style: normal;
font-weight: normal;
font-size: 20px;
color: rgba(255,255,255,1);
}
.Get_Your_Documents_printed_Sec {
white-space: nowrap;
text-align: left;
font-family: "Segoe UI";
font-style: normal;
font-weight: bold;
font-size: 50px;
color: rgba(248,200,17,1);
line-height: 1.34;
}
.alx{
position: relative;
top: 10px;
}
.get-btn{
background: linear-gradient(256deg, #359b76 97%, #32795f 0%);  
padding: 10px;
border-radius: 5px;
box-shadow: 0 10px 20px 0 rgba(50, 121, 95, 0.2);
width: 35%;
position: relative;
top: 25px;
cursor: pointer;
}
.get-btn a{
color:#fff;
text-decoration: none;
font-weight: normal;
    cursor: pointer;
    font-size: 18px;
    font-family: 'Segoe UI';
cursor: pointer;
}
.left-wrk {
position: relative;
top: 140px;
left:40px;
}
.left-wrk h2 {
font-size: 50px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.34;
font-family: 'Segoe UI';
 
}
ul.list-nav li a {
font-size: 18px;
color: #6a6969;
text-decoration: none;
}
ul.list-nav1 {
margin: 0;
padding: 0;
}
.txt h2 {
font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    font-family: 'Segoe UI';
text-align: center;
position: relative;
top: 20px;
}
ul.list-nav span i {
color: green;
font-weight: 600;
}
ul.list-nav1 li {
display: inline-block;
margin: 10px 6px;
position: relative;
top: 27px;
}
.header-line{
position: relative;
bottom: 0px;
}
.logo {
padding: 5px;
}
font.grn-txt {
color:#32795f;
cursor: pointer;
}
li.header-btn {
background: #34916f;
padding: 10px;
border-radius: 6px;
}
li.header-btn a {
color:#fff!important;
}
.right-header-top{
text-align: right;
}
ul.list-nav1 li a {
text-decoration: none;
font-size: 13px;
color: #2d882d;
font-weight: 600;
}
ul.list-nav {
margin: 0;
padding: 0;
text-align: center;
}
.video-left h2 {
font-size: 50px;
font-weight: bold;
font-stretch: normal;
font-style: normal;
line-height: 1.34;
color: #f8c811;
font-family: 'Segoe UI';
margin: 0px 0px;

}
.video-left h3 {
font-size: 50px;
font-weight: bold;
font-stretch: normal;
font-style: normal;
line-height: 1.34;
color: #484848;
font-family: 'Segoe UI';
margin: 6px 0px;
}
.logo-footer p {
text-align: left;
    position: relative;
    top: 20px;
    font-size: 15px;
    color: #6d6d6d;
    line-height: 25px;
}
.navbar {
position: relative;
top: 35px;
}
.vid-section{
width: 1095px;
margin-top: 0px;
margin-bottom: 40px;
}
.logo-footer img {
width: 35%;
}
.ft{
background-image: url('../assets/footer.jpg');
padding: 180px 90px 50px 90px;
background-size: cover;

}

.left-wrk p {
width: 90%;
font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
color: #6c6969;
}
.video img{
width:100%;
}
.video-left p {
   color: #969494;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    font-family: 'Segoe UI';
margin: 20px 0px 0px 0px;  

}
.extra-line{
margin-top:7px!important;
}
.vid-btn{
text-align: center;
top: 12px;
display: inline-block;
width: 37%!important;
}
.vid-btn a{
font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;

}
.gtm1-txt a {
background: linear-gradient(250deg, #359b76 96%, #32795f);
padding: 14px 25px;
box-shadow: 0 10px 20px 0 rgba(50, 121, 95, 0.2);
border-radius: 5px;
color: #fff;
font-size: 18px;
font-weight: 600;
font-stretch: normal;
font-style: normal;
line-height: 1.33;
position: relative;
top: 40px;
text-decoration: none;
}
.lnk{
color: rgb(53 147 113);
font-size: 16px;
font-weight: 600;
display: inline-block;
position: relative;
top: 14px;
left: 20px;
cursor: pointer;
}

.inside-content p {
width: 60%;
text-align: center;
margin-left: auto;
margin-right: auto;
position: relative;
top: 15px;
font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
margin-bottom: 125px;
color: #6d6d6d;
font-family: 'Segoe UI';
}
.inside-content h2 {
text-align: center;
color: #5b5858;
font-family: 'Segoe UI';
  font-size: 50px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.34;
}
.inside-content {
position: relative;
top: 60px;
}
.video-left {
margin: 30px 0px;
}
.right-gle h2 {
font-size: 50px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.34;
font-weight: 600;
font-family: 'Segoe UI';

}
.right-gle h2 font {
color:#f8c811;
}
.card p {
font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
color:#6d6d6d;
font-family: 'Segoe UI';
}
.right-gle p {
    width: 100%;
    color: #6d6d6d;
    line-height: 30px;
    font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
}
.right-gle {
position: relative;
top: 50px;
}
ul.gle-btn {
margin: 0;
padding: 0;
}
ul.gle-btn li img {
width: 100%;
position: relative;
right: 9px;
}
ul.gle-btn li {
display: inline-block;
list-style-type: none;
width: 30%;
text-align: center;
}
.scan-txt{
font-size: 26px!important;
font-weight: 600!important;
margin: 15px;
font-size: 26px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  color:#484848!important;
}
ul.bar-code{
margin: 0;
padding:0;
}
.left-wrk font {
color: #f8c811;
}
.work {
margin: 70px 0px;
}
.work img {
width: 100%;
}
ul.bar-code li {
display: inline-block;
list-style-type: none;
margin: 10px 10px;
}
ul.print-list {
margin: 0;

padding: 0px 0px 20px 0px;
border-bottom: 1px solid #c0bcbc;
}
.get {
display: inline-block;
width: 100%;
background: #ffd400;
border-radius: 10px;
position: relative;
top: 90px;
margin-bottom: 50px;
}
.gt-txt p {
margin: 10px 0px 40px 0px;
    text-align: left;
    font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
    color: #6d6d6d;
    font-family: 'Segoe UI';
}
.gt-txt h2 {

font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    font-family: 'Segoe UI';
margin: 50px 0px 0px 0px;
text-align: left;
font-weight: 600;
color:#484848;
}
ul.print-list li {
display: inline-block;
list-style-type: none;
margin: 10px 25px;
}
ul.print-list span{
font-size: 26px !important;
font-weight: 600;
color: rgba(50,121,95,1);
}
.thumb-txt ul li {
text-align: left;
 
  color: #7c7979;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  font-family: 'Segoe UI';
}
.bx {
display: inline-block;
width: 100%;
position: relative;
top: -50px;
background-image: url('../assets/001.png');
background-repeat: no-repeat;
background-size: cover;
padding-bottom: 230px;
padding-top: 50px;
margin: 0px 0px 0px 0px;
}
.service-page .bx {
display: inline-block;
width: 100%;
position: relative;
top: 0px;
background-image: url('../assets/001.png');
background-repeat: no-repeat;
background-size: auto;
padding-bottom: 150px;
padding-top: 230px;
}
.overly {

width: 100%;
background-size: cover;
display: inline-block;
padding: 100px;
}
.inside-content font {
color: #f8c811;
}
.dt-pv{
  position: relative;
    top: 75px;
}
.inside-content {
position: relative;
top: 60px;
}
.bx-align{
position: relative;
bottom: 0px;
}
.bx-align1{
position: relative;
bottom: -20px;
}
.prt {
position: relative;
left:20px;
color:#848383!important;
font-size: 26px!important;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  font-family: 'Segoe UI';
}
.gtm-txt a {
background: linear-gradient(252deg, #359b76 96%, #32795f);
padding: 18px 30px;
color: #fff;
border-radius: 5px;
text-decoration: none;
margin-right: 15px;
cursor: pointer;
box-shadow: 0 10px 20px 0 rgba(50, 121, 95, 0.2);
font-size: 18px;
font-weight: 600;
font-stretch: normal;
font-style: normal;
line-height: 1.33;
}
.orz{
font-weight: 600;
color: #686464;
font-size: 18px;
margin-right: 15px;
}
.prt1{
position: relative;
left:26px;
font-size: 17px!important;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  font-family: 'Segoe UI';
}
.img-cntr img{
width:85%;
}
.card h4 {
font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  color: #434242;
  padding: 10px 0px 0px 0px;
  font-family: 'Segoe UI';
  
}
.card img {
width: 40%;
}
.react-tabs__tab{
  display: flex;
  padding-left:0px;
}
.react-tabs__tab img{
  /* width:35px; */
  width: 50px;
    height: 45px;
    position: relative;
    left: 0px;
    top: 26px;
}
.printing-services h2 {
font-size: 50px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.34;
padding-bottom:15px;
font-family: 'Segoe UI';
  
}
.printing-services p {
width: 90%;
margin-left: auto;
margin-right: auto;
font-size: 20px;
color:#6d6d6d;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
font-family: 'Segoe UI';
}
.printing-services  {
text-align: center;
margin:15px 0px;
display: inline-block;

}
.printing-services font {
color: #f8c811;
}
.card {

text-align: center;
background: #fff;
padding: 20px 20px 20px 20px;
border-radius: 12px;
box-shadow: 0px 0px 15px 0px #a3c5b891;
}
.orx{
position: relative;
top: 80px;
}
.bx .container {
width: 1024px;
}
.lnx{
margin-bottom:20px;
}
.nme{
font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  font-family: 'Segoe UI';
color: #a7a4a4;
position: relative;
top: 10px;
}


/*tabs css*/
.react-tabs {
   
    margin-left: 20px;
    width: 100%;
    height: auto;
    color: #000;
    background: #fff;
    margin-bottom:60px;
    text-align: center;
    display: inline-flex;
  }

  
  .react-tabs__tab-list {
   
   
    color: white;
    background: #fff;
    margin-right: 16px;
  }
  
  .react-tabs__tab {
    height: 123px;
    list-style: none;
    padding: 12px 6px;
    cursor: pointer;
    color: #000;
    font-size: 20px;
    font-weight: 600;


  }
  
  .react-tabs__tab--selected {
   background: #32795f;
    /* border-color: #afe9cc; */
    border-left: 4px solid #6eb800;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    border-radius: 25px;
    border: 2px solid #519f7852;
  }

  .cardx p {
    font-size: 16px;
  color: #7f8282;
  line-height: 30px;
}
.ct{
    margin-left: 15px;
    font-weight: 600;
    color: #424040;
    position: relative;
    bottom: 10px;
}
.user {
    text-align: left;
    position: relative;
    top: 20px;
}
.cardx {
    width: 100%;
    background: #e9f4f3;
    padding: 10px 40px 40px 40px;
    border-radius: 50px;
    margin-left: auto;
    margin-right: auto;
}
.tst-qt{
    width: 60%;
  position: relative;
  top: 140px;
  text-align: left;
  font-size: 16px;
  font-family: 'Segoe UI';
  color: #7c7979;
}
.des1 {
  display: block;
    position: relative;
    left: 75px;
    bottom: 31px;
    font-size: 14px;
    font-family: 'Segoe UI';
}
  .react-tabs__tab {
    padding-left: 24px;
    border: 2px solid #32795f29;
    border-radius: 25px;
   
    margin: 20px 5px;
    position: relative;
    top: 20px;
  }
  
  .react-tabs__tab--selected {
    padding-left: 21px;
  }
  
.panel-content {
  text-align: left;
  position: relative;
  left: 30px;
  bottom:10px;
}
.tst-qt{
  width: 60%;
  position: relative;
  top: 140px;
  text-align: left;
  
}
.ftx p {
width: 58%;
margin-left: auto;
margin-right: auto;
font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    text-align: center;
color: #6a6565;
position: relative;
top: 13px;
}
.ftx h2 {
text-align: center;
font-size: 40px;
font-family: 'Segoe UI';
  font-weight: 600;
}
.ftx font{
color:#f8c811;
}
.ftx h3{
text-align: center;
position: relative;
top: 20px;
font-family: 'Segoe UI';
  font-weight: 600;
}

@media screen and (max-width: 280px) {
  .Get_Your_Documents_printed_Sec {
      font-size: 25px !important;
  }
  .video-left h2 {
      font-size: 24px !important;
  }
  .get-btn {
      padding: 0px 0px 7px 0px !important;
  }
  .vid-btn a {
      font-size: 12px !important;
  }
  .right-gle h2 {
      font-size: 26px !important;
  }
  ul.print-list li {
      margin: 10px 7px !important;
  }
  .txt h2 {
      font-size: 15px !important;
  }
  .gt-txt h2 {
      font-size: 20px !important;
  }
  .gtm-txt a {
      padding: 14px 5px !important;
      font-size: 13px !important;
  }
  .react-tabs__tab p {
      font-size: 20px !important
      }
      .bx1 {
      margin-top: 1000px !important;
  }
  .bx1 h2 {
      font-size: 24px !important;
  }
  .ct {
      font-size: 14px !important;
  }
  .circle{
    width:auto !important;
  }
  .top-navi-btn .download-top {
      font-size: 10px !important;
  }
  .lnk{
    left:13px !important;
  }
  .service-page .prt1{
    font-size: 13px!important;
  }
  .service-page .gtm-txt a {
      padding: 14px 7px !important;
      font-size: 11px !important;
  }
  .service-detail-top .header-line {
      font-size: 18px !important;
  }
  .service-detail-get .gtm-txt a {
      padding: 14px 7px !important;
      font-size: 10px !important;
  }
  .service-detail-get .gt-txt h2 {
      font-size: 19px !important;
  }
}

@media screen and (max-width: 2000px) {
.top-nav-align .navBarContent {
  width: 57%;
}
.bt {
    
    margin-bottom: 0px;
    display: inline-block;
    width: 100%;
}
.faq-align {
    
    right: 0px;
}
.left-gle {
    position: relative;
    right: 430px;
    top:60px;
}
.service-detail-test {
    margin-top: 80px;
}
.fq-t {
    overflow: hidden;
}
.mask {
    left: 1330px!important;
    width: 40%;
}
.social-icon {
    position: fixed;
    bottom: 260px;
    transform: rotate( 
90deg );
    left: -4%;
}
._temp-btnCont-37 .MuiButton-label{
font-size: 20px;
}


.MuiSnackbarContent-message span{
font-size: 15px;
}
.MuiInputBase-input{
font-size: 12px!important;
}
.service-seq{
background-size: cover!important;
}

  .btz h3 {
    width: 47%;
    margin-left: auto;
    margin-right: auto;
}

.footer-menu h3{
left:0;
font-weight: 600;
text-align: left;
}
p.qte{
left: 0;
}
.cardx p {
  font-size: 18px;
  text-align: justify;
}
.Group_12438{
top:0;
}
.bx1 h2 {
  left: 160px;
  text-align: center;
}
p.arrow span {
  margin: 5px;
  position: relative;
  right: 520px;
  top:100px;
}
.tst-qt{
left: 120px;
  text-align: center;
}
.jumbotron.text-center {
  
  background-size: cover;
  padding: 160px 0px 600px 0px;
}
}  
@media screen and (max-width: 1920px) {
  .top-nav-align .navBarContent {
      width: 80%;
  }
  
  .fq-t{
    overflow: hidden;
  }
  .faq-align {
    left: 117px;
}
  .mask{
   left: 980px!important;
    width: 60%;
  }
  .jumbotron.text-center{
    padding:160px 0px 430px 0px;
    }
}

@media screen and (max-width: 1470px) {
.top-nav-align .navBarContent {
  width: 85%;
}
.left-gle {
    position: relative;
    right: 390px;
    top: 60px;
}
.fq-t {
    overflow: hidden;
}
.faq-align {
    left: -75px;
}

.mask {
  width:49%;
  left: 850px!important;
}
.header-sb{
  width:830px;
}
.social-icon{
position: fixed;
    bottom: 260px;
    transform: rotate( 
90deg );
    left: -5%; 

}
.service-seq{
padding-top:50px!important;
}
.Group_12438 {
  top: -90px;
  left: 125px;
}
p.arrow span {
  margin: 5px;
  position: relative;
  right: 200px;
  top: 80px;
}
.btz h3 {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}
.siq_bR {
    bottom: 20px!important;
   
}
.footer-menu h3{
left:0;
font-weight: 600;
text-align: left;
}
p.qte{
left: 0;
}
.cardx p {
  font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
  text-align: justify;
}
.bx1 h2 {
  text-align: center;
  left: -10px;
}
.tst-qt{
text-align: center;
  left: -20px;
}
.jumbotron.text-center {
  /* background-image: url(/static/media/backGround.bd6d5837.jpg); */
  background-size: cover;
  padding: 160px 0px 300px 0px;
}
}  
@media screen and (max-width: 1300px) {

.mask {
  
  left: 750px!important;
}
.bt {
    margin-bottom: -5px;
    display: inline-block;
    width: 100%;
}
.siq_bR {
    bottom: 40px!important;
}
.faq-align {
    left: -48px;
}
.btz h3 {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}
.social-icon{
position: fixed;
    bottom: 280px;
    transform: rotate( 
90deg );
    left: -6%; 

}
}
@media screen and (min-width: 1280px) {

.mask {
  
  left: 284px;
}
.header-sb{
  width:820px;
}
}
@media screen and (max-width: 1199px) {
.btz h3 {
    width: 93%;
    margin-left: auto;
    margin-right: auto;
}
.btz {
    text-align: left;
}

  }
@media screen and (max-width: 1024px) {
ul.list-nav li {
  display: block;
  
}
.cokee-privacy {
    margin-top: 100px;
}
.about-logo {
   
    width: 25%;
    height: 232px;
    margin-bottom: 20px;
}
.service-page ul.print-list li {
    width: 60%;
}
.terms{
  margin-top:36px;
}
.privacy {
    margin-top: 36px;
}
.bx{
  margin-top:-90px;
}
.get{
  padding-bottom:35px;
}
.logn div:nth-child(1) {
width:120px;

  }
  .orx {
    position: relative;
    top: 0px;
}
  .service-detail-tbs .react-tabs__tab-panel{

margin-top: -20px;
}
.log-num input {
    width: 100%;
    }
    .log-num .MuiFormControl-root{
      width:100%!important;
    }
    .log-num .MuiOutlinedInput-root{
      width:74%!important;
    }
    .log-num {
    width: 100%;
}
.fq-t {
    overflow: inherit;
}
h2.fq {
    margin-top: -70px;
}
.sign-box{
  bottom:24px!important;
  margin-bottom: 10px;
}
.terms {
    width: 90%!important;
    display: block!important;
}
 .privacy .privacyHeading {
    font-size: 15px!important;   
   font-weight: bold;
}
.privacy{
    width: 90%!important;
    display: block!important;
    flex-basis: inherit;
    margin-left: auto;
    margin-right: auto;
}
.vid-section .video {
    margin: 25px 0px 20px 0px;
}
.service-detail-get .gt-txt h2 {
    margin: 50px 0px 0px 0px;
}
.txt h2 {
    text-align: center;
}
.service-print-detail .ndx1 {
   
    margin-bottom: 50px;
}
.btz h3 {
    
    width: 95%;
}
.service-detail-get .gt-txt p {
    width: 100%!important;
    position: relative;
    right: 0px!important;
}
.service-print-detail .ndx {
    position: relative;
    top: 0px;
    margin-top: 0px;
}
.img-cntr img {
    width: 100%;
}
.grid-align {
    width: 100%;
}
.video.vid-ld video {
    width: 100%;
    height: auto;
    -o-object-fit: cover;
    object-fit: cover;
}
.left-gle {
    position: relative;
    right: 40px;
    top: 0px;
}
.mask {
    width: 49%;
    left: 0px!important;
}
.navMobileContent{
  padding-bottom:0px;
}
.Group_12438 {  
    top: 0;
}
.alx {
    
    width: 50%;
    display: inline-block;
}
.jumbotron.text-center {
    padding: 160px 0px 0px 0px;
    background-position: bottom;
}
.sb1 {
    left: -150px!important;
}
.header-sb {
    left: 0px;
}
.top-nav-align .navMiddle {
   
    position: relative;
    left: 9px;
}
.top-nav-align .navRight {
    position: relative;
    right: 8px;
}
.header-nav-right .MuiButton-text {
    width: 80px;
    padding:6px 0px;
}
.tabs-view-fix .react-tabs__tab-panel {
    
    padding-bottom: 40px;
}
.top-nav-align .navBarContent {
  width: 100%;
}
.service-detail-tbs .react-tabs__tab-panel {
    
  padding-bottom: 50px!important;
}
.service-test{
  margin-top: 0px!important;
}
.bx1{
margin-top:560px;
}
.video-left h2 {
  font-size: 30px;
  }
  .video-left h3 {
  font-size: 30px;
}
.video-left {
  margin: 0px 0px;
}
.react-tabs{
margin-left: 0;
}
.dot1 {
  
  right: 0px;
}
.txts{
  width:100%!important;  
}
.cardx {
  
  padding: 20px;
  margin-bottom: 20px;
  }
.des {
  display: block;
  position: relative;
  bottom: 20px;
  font-size: 14px;
  width: 23%;
  text-align: center;
}
.ndx1 .row{
  margin: 110px 0px 0px 0px;
}
.ndx {
  position: relative;
  top: 0px;
}
.det .img-cntr {
   margin-bottom: 0px; 
  position: relative;
  top: 50px;
}
.service-page .bx .container {
  width: auto;
  margin: 0px 0px 0px 0px;
}
.service-page .orx {
  position: relative;
  top: 0px;
  padding-bottom: 40px;
}
.service-page .gtm-txt a {
  background: #34916f;
  padding: 14px 15px;
  }
.nvs {
  display: inline-block;
  width: 100%;
}
.toggle{
position: absolute;
  right: 25px;
  top: 40px;
}
.toggle i{
font-size: 30px;
}
.ftx h2 {
  text-align: center;
  font-size: 30px;
}
.ft {
 
  padding: 70px 90px 50px 90px;
  background-size: cover;
}
ul.list {
  text-align: left;
}
.footer-menu h3 {
  font-size: 14px;
  text-align: left;
  color: #000;
  position: relative;
  right: 0px;
}
.logo-footer {
  text-align: left;
}
.gt-txt h2 {
  font-size: 28px;
  color: #000;
  margin: 50px 0px 0px 0px;
  text-align: left;
  font-weight: 600;
}
.gt-txt p {
  margin: 10px 0px 40px 0px;
  text-align: left;
  font-size: 20px;
}
.footer-menu{
margin: 10px 0px 10px 0px;
  display: inline-block;
}
.vid-btn {
 
  width: 25%!important;
}
.inside-content p {
  width: 85%!important;
  }
.bx .container {
  width: auto;
 margin: 0px 0px 0px 0px;
}
.printing-services {
  
  margin: 0px 0px;
  
}
.prt {
  position: relative;
  left: 0px;
  color: #848383!important;
}
ul.print-list li img {
  width: auto;
}
.tst {
  margin-bottom: 120px;
  margin-top: 70px;
}
.overly {
 
  padding: 0px;
}
.bx{
padding-bottom: 0;
}
.left-wrk {
 top:0px;
  left: 10px;
}
.bx-align {
  position: relative;
  bottom: 0px;
}
.mult {
    position: relative;
    top: 0px;
}
.dt-pv {
    position: relative;
    top: 45px;
}
.card img {
    width: 20%;
}
.inside-content p {
  width: 100%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 15px;
  font-size: 16px;
  margin-bottom: 0px;
}
.inside-content {
  position: relative;
  top: 0px;
    margin-top: 220px!important;
    display: inline-block;
    margin-bottom: 50px;
}
.react-tabs__tab img {
 
  left: -15px;
 
}
.bx-align1 {
 bottom:0px!important;
  margin-top: 60px!important;
}
.get {
  width:96%;
  left: 2px;
}
 .gtm-txt a {
  
  padding: 14px 21px;
  } 
  .card {
  padding: 80px;
  margin-bottom: 20px;
}
  
​
​
.bx .container {
  width: auto;
}
.video-left p {
 
  top: 14px;
  }
.vid-section {
  width: auto;
  margin:0;
 
}
ul.print-list li {
 
  margin: 10px 20px;
}
ul.print-list span {
  font-size: 16px;
  
}
ul.list-nav {
  text-align: left;
}
span.toggle {
  display: block;
}
.right-header-top {
  text-align: left;
  margin-bottom: 60px;
  display: none;
}
.navbar{
display: none;
}
ul.list-nav1 li {
  display: block;
  margin: 10px 6px;
  position: relative;
  top: 27px;
  width: 45%;
  text-align: left;
  left: 24px;
}
#Get_Your_Documents_printed_Sec {
 
  font-size: 25px;
  
}
 .get-btn {
  
  width: 25%;
  position: relative;
  top: 50px;
}
.des1{
left: 75px!important;
text-align: left;
width:50%;
}
.dot{
left:0;
}
#Group_12438 {
  width: 100%;
  height: auto;
  left: 0px;
  top: 0px;
  position: relative;
}
.Group_12438 {
  width: 100%;
  height: 327px;
  left: 0px;
}
.react-tabs{
  display: block;
}
.navMobileContent {
  display: inherit!important;
}
.panel-content{
  left: 20px!important;
  width:95%;
}
.tabs-view-fix {
    position: relative;
    right: 6px;
}
.wt1 {
  font-size: 15px;
}
.jumbotron.text-center {
  
  padding: 160px 0px 100px 0px;
}
.Get_Your_Documents_printed_Sec{
font-size: 32px;
}
.react-tabs__tab-list {
  
  margin: 0;
  padding:0;
}
.testimonial-align {
  
  margin-top: 80px;
}
.left-wrk p {
  width: 95%;
  
}
.social img {
  width: 7%;
   transform: none;
}
.testimonial-inner-content h2 {
  
  font-size: 35px;
  }
.testimonial-inner-content p {
  width: 90%;
  left: 10px;
}
p.arrow span{
  top:0;
}
.MuiGrid-container{
  display: inline-block;
  
}
.react-tabs__tab-panel{
  height: auto;
}
.mask {
  
  width: 100%;
  left: 0px;
}
.faq-align {
  width: 80%!important;
  position: relative;
  left: 0px;
}
.tst-qt{
width:100%;
left: 13px;
}
h2.fq {
  margin-top: -110px;
  }
.bx1 h2{
width:100%;
left: 13px;
}
.btz h3 {
  left: 10px!important;
}
.service-detail-heading  h2 {
  font-size: 35px;
  line-height: 50px;
  }
.service-detail-test{
margin-top: 100px!important;
}
.service-detail-get .get{
top:0;
}
.service-detail-get .gtm-txt a {
  padding: 14px 15px;
}
.service-print-detail p.line-px {
  width: 90%!important;
}
.service-print-detail .print-section {
  text-align: center;
 
}
.service-detail-tbs .react-tabs__tab-panel .panel-content h2 {
  
  font-size: 25px;
}
.service-detail-qt  {
  margin-top: 0px!important;
}
.service-detail-tbs .react-tabs__tab img {
  left: -15px;
}
.service-detail-tbs .react-tabs__tab-panel{
position: relative;
}
.service-vid-align .video{
margin:30px 0px 50px 0px;
}
.service-detail-top .header-line{
font-size: 25px;
}
.social-icon {
  position: inherit;
  bottom: 0;
  transform: inherit;
  left: 0%;

}

.web-st img {
    height: auto;
    }
}
@media screen and (max-width: 768px) {
.navRight {
    position: relative;
    right: 10px;
}
.cokee-privacy {
    margin-top: 0px;
}
.about-logo {
    width: 35%;
    
}
.log-num .MuiOutlinedInput-root{
      width:65%!important;
    }
.service-print-detail .txt h2{
  text-align: center;
}
.logn div:nth-child(1) {
width:120px;

  }
.service-page .get {
   
    width: 100%;
    left: -12px;
}
.vid-section .video {
    margin: 25px 0px 20px 0px;
}
.dot {
   
    position: relative;
    bottom: 70px;
   
}
.vid-ld {
    position: relative;
    top: 50px;
    
}
.drop-menu1 h3 {
    margin: 0px 0px 10px 0px;
    font-size: 19px;
    font-weight: 600;
}
.services_mobile_submenu.main1 {
    display: block;
}
.drop-menu h3 {
    margin: 0px 0px 10px 0px;
    font-size: 19px;
    font-weight: 600;
}
p.read {
    font-size: 18px;
    font-weight: 600;
    text-align: left;
}
.drop-menux {
    display: inline-block;
    text-align: center;
    margin: 0px 12px;
}
.services_mobile_submenu.drop-menu.main {
    display: block;
}
.sign-top{
  width:60px!important;
}
.img-cntr img {
    width: 100%;
}
.grid-align {
    width: 100%;
}
.left-gle img {
    width: 100%;
}
.left-gle {
    position: relative;
    right: 25px;
    top: 0px;
}
.left-gle{
  position: relative;
  right:20px;
}
.video.vid-ld video {
    width: 100%;
    height: auto;
  }
.mask {
   
    left: 0px!important;
}
.txt h2 {
   
    text-align: left;
    
}
.top-navi-btn .download-top {
    width: 215px!important;
    margin: 20px!important;
}
.download-top {
    background: linear-gradient(to top right, #337c61 0%, #3bae85 100%);
    padding: 4px 8px 4px 8px;
    border-radius: 4px!important;
}
.sign-top {
    background: none!important;
    padding: 4px 8px 4px 8px;
    border-radius: 4px!important;
}
.sign-top span {
    color: #000;
}
.drop-menu1 {
    display: none;
}
.drop-menu {
    display: none;
}
.menu-ico {
    bottom: 0px;
    display: inline-block;
    margin-top: 18px;
}
.menu-ico1 {
    bottom: 0px;
    display: inline-block;
    margin-top: 18px;
    margin-left: 0;
    margin-right: 0;
}
.menu-ico2 {
    bottom: 0px;
    display: inline-block;
    margin-top: 18px;
    margin-left: 0;
    margin-right: 0;
}
.menu-ico3 {
    bottom: 0px;
    display: inline-block;
    margin-top: 18px;
    margin-left: 0;
    margin-right: 0;
}
.menu-ico4 {
    bottom: 0px;
    display: inline-block;
    margin-top: 18px;
    margin-left: 0;
    margin-right: 0;
}
.top-nav-align {
    position: inherit;
}
 .navBarTopMargin {
    height: auto;
}
.menu-ico5 {
    bottom: 0px;
    display: inline-block;
    margin-top: 18px;
    margin-left: 0;
    margin-right: 0;
}
.menu-ico6 {
    bottom: 0px;
    display: inline-block;
    margin-top: 18px;
    margin-left: 0;
    margin-right: 0;
}





p.read {
    font-size: 18px;
    font-weight: 600;
    text-align: left;
}
.menu-ico {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0px;
   
}
.menu-ico{
  margin-left: 0;
  margin-right: 0;
}
.services_mobile_submenu {
    margin-left: 0px;
   
}
.top-navi-btn .download-top {
    width: 153px!important;
    margin: 20px 30px 20px 30px!important;
}
.get-btn {
    width: 30%;
    
}
  }
@media screen and (max-width: 540px) {
   ul.list-nav li {
  display: block;
  
}
.cokee-privacy {
    margin-top: 0px;
}
p.cokee-quote {
  margin: 5px;
  text-align: justify;
}
.cokee-privacy p {
    font-size: 13px!important;
}
.about-logo {
   
    width: 100%;
    height: 232px;
    margin-bottom: 20px;
}
.about-content-size {
    margin: 20px 0px;
}
.service-page ul.print-list li img {
    width: auto;
}
.bt {
    
    margin-bottom: 0px;
    display: inline-block;
    width: 100%;
}
.terms{
  margin-top:36px;
}
.privacy {
    margin-top: 36px;
}
.bg-auth{
  max-width: 100%!important;
}
.service-page ul.print-list li {
    width: 90%;
}
.service-page .prt1 {
   font-size: 20px;
    left: 30px;
}
.get {
    padding-bottom: 35px;
}
.gtm-txt {
    position: relative;
    bottom: 20px;
}
.gt-txt{
  position: relative;
  bottom:10px;
}
.privacy p {
    font-size: 15px;
    
}
.login_number_cont {
    width: 86%;
    display: flex;
    align-items: center;
    flex-direction: row;
}
.log-num input {
    width: 100%;
    }
    .log-num .MuiFormControl-root{
      width:100%!important;
    }
    .log-num .MuiOutlinedInput-root{
      width:70%!important;
    }
    .log-num {
    width: 90%;
}
.service-prt .get {
    padding-bottom: 0px;
}
.get{
  padding-bottom: 50px;
}
.orx {
   
    top: 0px;
}
.fq-t {
    overflow: inherit;
}
h2.fq {
    margin-top: -90px;
}
.service-page .printing-services p {
   
    margin-bottom: 25px;
}
.digital .gtm1-txt a{
  top:0;
}
.ftx p {
    width: 95%;
    }
.left-wrk h2 {
    font-size: 37px;
    }
.right-gle p {
    width: 100%;
  }
.logn div:nth-child(1) {
width:100px;

  }
  
.log-ico{
  margin: 0px !important;
  position: relative;
    bottom: 50px;

}
.digital {
    margin: 50px 0px 0px 0px;
    
}
.left-side {
    padding: 60px 10px 150px;
}
.bg-auth .auth-btn{
  font-size: 13px!important;
}
.privacy .privacyHeading {
    font-size: 15px!important;   
   font-weight: bold;
}
.privacy{
    width: 90%!important;
    display: block!important;
    flex-basis: inherit;
    margin-left: auto;
    margin-right: auto;
}
.terms p{
  font-size: 15px;
}
.terms {
    width: 90%!important;
    display: block!important;
    margin-left: auto;
    margin-right: auto;
}

.prt1{
  font-size: 20px!important;
  left: 10px;
}
.service-page .bx {
    
    position: relative;
    top: 30px;
    }
.service-print-detail .ndx1 {
  
    margin-bottom: 30px;  
}
.vid-ld {
    position: relative;
    top: 45px;
    display: flex;
}
.bottom-ft ul {
    position: relative;
    bottom: 5px;
    display: block;
}
.bottom-ft ul li {
    
    font-size: 15px;
    margin: 0px 15px 5px 15px;
    
}
.service-vid  {
    margin: 0px 0px 30px 0px!important;
}
.btz h3 {
    width:65%;
    left: -28px!important;
}
.tabs-view-fix .react-tabs__tab-panel {
    height: auto;
}
.grid-align {
    width: 100%;
}
.faq-align p.MuiTypography-body1 {
    font-size: 12px!important;
    padding-left: 10px;
    display: inline-flex;
    }
.filx{
  width:100%;
}
.filx1{
  width:100%;
}
.filx2{
  width:100%;
}
.filx3{
  width:100%;
}
.left-gle img {
    width: 100%;
}
.left-gle {
    position: relative;
    right: 17px;
    top: 0px;
}
.mult {
    position: relative;
    top: 5px;
}
.dt-pv {
    position: relative;
    top: 45px;
}s
.right-gle p {
    
    width: 100%;
    }
.video.vid-ld video {
    width: 100%;
    height: auto;
    
}
.top-navi-btn .download-top {
    width: 215px!important;
    margin: 20px!important;
}
.top-navi-btn .download-top {
font-size: 13px;

}
.bottom-ft {
       padding-left: 0px;
}
.print-section h2 {
    
    margin-bottom: 20px;
    }
    .img-cntr {
    margin-top: 30px;
}
.drop-menu {
    display: none;
}
.digital-txt{
  display: block;
    text-align: left;
}
.digital-txt .menu-ico{
  display: block;
}
.drop-menu1 {
    display: none;
}
.drop-menux {
    display: block;
    text-align: left;
    margin: 0px 12px;   
}

.drop-menu1 h3 {
    margin: 0px 0px 10px 0px;
    font-size: 19px;
    font-weight: 600;
}
.main {
  display: block;
}
.mainDisplayNone {
display: none;
}
.main1 {   
    display: block;
}
.service-print-detail .txt h2 {
    text-align: center;
    font-size: 14px;
}
.siq_bR {
    bottom: 100px;
    right: 10px;
    z-index: 0!important;        
}
.navBarTopMargin {
    height: auto;
}
.top-nav-align{
  position: inherit;
}
.service-page .get {
    top: 0px;
    width: 100%;
    left: 0px;
}
p.read {
    
    text-align: left;
}
.drop-menu h3{
  margin: 0px 0px 10px 0px;
    font-size: 19px;
    font-weight: 600;
}
span.navMobileMenuItem img {
    display: block!important;   
}
.menu-ico {
    
    bottom: 0px;
    display: inline-block;
    margin-top: 18px;
}
.menu-ico1 {
    
    bottom: 0px;
    display: inline-block;
    margin-top: 18px;
}
.menu-ico2 {
    
    bottom: 0px;
    display: inline-block;
    margin-top: 18px;
}
.zsiq_floatmain.zsiq_theme1.siq_bR {
    bottom: 133px!important;
    right: 10px;
    z-index: 0!important;    
}
.top-nav-align .navMobileMenuItem{
  font-size: 18px;
}
.menu-ico3 {
    width:64px;
    bottom: 0px;
    display:block;
    margin-top: 18px;
    margin-left: inherit;
    margin-right: inherit;
}
.menu-ico4 {
    
    bottom: 0px;
    display:block; 
    margin-top: 18px;
    margin-left: inherit;
    margin-right: inherit;
}
.menu-ico5 {
    width:64px;
    bottom: 0px;
    display:block;  
    margin-top: 18px;
    margin-left: inherit;
    margin-right: inherit;
}

 .navMobileButton {
    display: flex;
    flex-wrap: wrap;
    margin-left: -30px!important;
    margin-right: 30px;
}
.arowx img{
  display: block!important;
}
span.navMobileMenuItem img {
    display: none;
}
.web-st img {
    height: auto;
    }
.social-icon {
  position: inherit;
  bottom: 0;
  transform: inherit;
  left: 0%;
}
.service-detail-test .des1 {
  left: 60px!important;
 
}
.device-view .navMobileMenuItem img{
display: none;
}
.service-detail-heading  h2 {
  font-size: 35px;
  line-height: 50px;
  }
.service-detail-test{
margin-top: -125px!important;
}

.service-detail-get .gt-txt p {
    width: 100%!important;
    position: relative;
    right: 0px!important;
}
.service-detail-get .get{
top:0;
}
.service-detail-get .gtm-txt a {
  padding: 14px 15px;
}
.service-print-detail p.line-px {
  width: 90%!important;
}
.service-print-detail .print-section {
  text-align: center;
 
}
.service-detail-tbs .react-tabs__tab-panel .panel-content h2 {
  
  font-size: 25px;
}
.service-detail-tbs .react-tabs__tab-panel {
    
  padding-bottom: 50px;
}
.service-print-detail .ndx {
    position: relative;
    top: 0px;
     margin-top: 0px;
}

.service-detail-tbs .react-tabs__tab img {
left: -15px;
width:70px;
height: 45px;
}

.tabs-view-fix .react-tabs__tab-panel {
  padding-bottom: 50px;
}
.tabs-view-fix{
width: 96%;
  margin-left: -5px;
  margin-right: 5px;
}

.bx{
padding-bottom: 25px!important;
margin:0px 0px 0px 0px;
}

.social img {
width: 10%;
position: relative;
left: 15px;
transform: none;
}
.service-detail-qt  {
  margin-top: 0px!important;
}
.service-detail-tbs .react-tabs__tab img {
  left: -15px;
}
.service-detail-tbs .react-tabs__tab-panel{
position: relative;
margin-top: -20px;
}
.service-vid-align .video{
margin:70px 0px 50px 0px;
}
.service-detail-top .header-line{
font-size: 25px;
}
.btz h3 {
  left: 0px;
}
.top-nav-align .navMobileContent{
padding-bottom:12px;
z-index: 1;
}
.MuiButton-label img{
padding-right: 7px;
}
.services_mobile_submenu {
  margin-left: 0px;
}
.top-nav-align .Header-navMiddleImg-58{
display: none;
}
.service-test{
  margin-top: 0px!important;
}
.service-seq{
margin: -150px 0px 50px 0px;
padding-top: 120px!important;
}
.service-message{
margin-top:0px!important;
}
.tabs-view-fix {
    position: relative;
    right: 0px;
}
.bx1{
margin-top:840px;
}
.video-left h2 {
  font-size: 30px;
  }
  .video-left h3 {
  font-size: 30px;
}
.video-left {
  margin: 0px 0px;
}
.react-tabs{
margin-left: 0;
}
.dot1 {
  
  right: 0px;
}
.txts{
  width:100%!important;  
}
.cardx {
  
  padding: 20px;
  margin-bottom: 20px;
  }
.des {
  display: block;
  position: relative;
  bottom: 20px;
  font-size: 14px;
  width: 23%;
  text-align: center;
}
.ndx1 .row{
  margin: 110px 0px 0px 0px;
}
.ndx {
  position: relative;
  top: 0px;
}
.det .img-cntr {
   margin-bottom: 0px; 
  position: relative;
  top: 50px;
}
.service-page .bx .container {
  width: auto;
  margin: 0px 0px 0px 0px;
}
.service-page .orx {
  position: relative;
  top: 0px;
  padding-bottom: 40px;
}
.service-page .gtm-txt a {
  background: linear-gradient(to top right, #337c61 0%, #3bae85 100%);
  box-shadow: 0 10px 20px 0 rgba(50, 121, 95, 0.2);
  padding: 14px 15px;
  }
.nvs {
  display: inline-block;
  width: 100%;
}
.toggle{
position: absolute;
  right: 25px;
  top: 40px;
}
.toggle i{
font-size: 30px;
}
.ftx h2 {
  text-align: center;
  font-size: 30px;
}
.ft {
 
  padding: 70px 30px 50px 30px;
  background-size: cover;
}
ul.list {
  text-align: left;
}
.footer-menu h3 {
  font-size: 14px;
  text-align: left;
  color: #000;
  position: relative;
  right: 0px;
}
.logo-footer {
  text-align: left;
}
.top-nav-align .Header-navMiddleImg-33{
display: none;
}
.service-detail-get .get {
    
    left: 10px;
}
.gt-txt h2 {
  font-size: 25px;
  color: #000;
  margin: 50px 0px 0px 0px!important;
  text-align: left;
  font-weight: 600;
}
.gt-txt p {
  margin: 10px 0px 40px 0px;
  text-align: left;
  font-size: 20px;
}
.footer-menu{
margin: 10px 0px 10px 0px;
  display: inline-block;
}
.vid-btn {
 
  width: 54%!important;
}
.inside-content p {
  width: 85%!important;
  }
.bx .container {
  width: auto;
 margin: 0px 0px 0px 0px;
}
.printing-services {
  
  margin: 0px 0px;
  
}
.prt {
  position: relative;
  left: 12px;
  color: #848383!important;
  font-size: 16px!important;
}
ul.print-list li img {
  width: 54px;
  height: 46px;
}
.tst {
  margin-bottom: 120px;
  margin-top: 70px;
}
.overly {
 
  padding: 0px;
}
.bx{
padding-bottom: 0;
margin-top:-130px;
}
.left-wrk {
 top:0px;
  left: 10px;
}
.bx-align {
  position: relative;
  bottom: 0px;
}
.wt1 {
    font-size: 13px!important;
}
.card img {
    width: 40%;
}
.inside-content p {
  width: 100%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 15px;
  font-size: 16px;
  margin-bottom: 30px;
}
.inside-content {
  position: relative;
  top: 0px;
  margin-bottom:10px;
  margin-top: 160px!important;
}
.react-tabs__tab img {
 
  left: -15px;
 
}
.bx-align1 {
 bottom:0px!important;
  margin-top: 60px!important;
}
.get {
  width:96%;
  left: 2px;
}
 .gtm-txt a {
  
  padding: 14px 21px;
  } 
  .card {
  padding: 20px;
  margin-bottom: 20px;
}
  
​
​
.bx .container {
  width: auto;
}
.video-left p {
 
  top: 14px;
  }
.vid-section {
  width: auto;
  margin:0;
 
}
ul.print-list li {
 
  margin: 10px 14px;
}
ul.print-list span {
  font-size: 16px!important;
  
}
ul.list-nav {
  text-align: left;
}
span.toggle {
  display: block;
}
.right-header-top {
  text-align: left;
  margin-bottom: 60px;
  display: none;
}
.navbar{
display: none;
}
ul.list-nav1 li {
  display: block;
  margin: 10px 6px;
  position: relative;
  top: 27px;
  width: 45%;
  text-align: left;
  left: 24px;
}
#Get_Your_Documents_printed_Sec {
 
  font-size: 25px;
  
}
.get-btn {
  
  width: 65%;
  position: relative;
  top: 50px;
}
.des1{
left: 65px!important;
  text-align: center;
  width: 50%;
}
.dot{
left:0;
}
#Group_12438 {
  width: 100%;
  height: auto;
  left: 0px;
  top: 0px;
  position: relative;
}
.Group_12438 {
  width: 100%;
  height: 327px;
  left: 0px;
}
.react-tabs{
  display: block;
}
.navMobileContent {
  display: inherit!important;
}
.panel-content{
  left: 12px!important;
}


.jumbotron{
background-size: cover;
    padding: 60px 0px 160px 0px!important;
    background-position: inherit!important;
  }
  .alx {
    width: 100%;
    display: inline-block;
}

.Get_Your_Documents_printed_Sec{
font-size: 32px;
}
.Get_Your_Documents_printed_Sec {
    font-size: 28px;
}
.react-tabs__tab-list {
  
  margin: 0;
  padding:0;
}
.testimonial-align {
  
  margin-top: 80px;
}
.left-wrk p {
  width: 95%;
  
}
.social img {
  width: 9%;
  position: relative;
  left: 15px;
}
.testimonial-inner-content h2 {
  
  font-size: 35px;
  }
.testimonial-inner-content p {
  width: 90%;
  left: 0px;
}
p.arrow span{   
  top:0px;
  right:185px;
}
.MuiGrid-container{
  display: inline-block;
  
}
.react-tabs__tab-panel{
  height: auto;
}
.mask {
  
  width: 100%;
  left: 0px!important;
}
.faq-align {
  width: 80%!important;
  position: relative;
  left: 0px;
}
.sign-top{
  position: relative;
    right: 20px;
    width:100px;
}
.sign-top{
  background: none!important;
    padding: 4px 8px 4px 8px;
    border-radius: 4px!important;
}
.sign-top span{
color:#000;
  }
.download-top{
background: linear-gradient(to top right, #337c61 0%, #3bae85 100%);
    padding: 4px 8px 4px 8px;
    border-radius: 4px!important;
  }
.tst-qt{
width:100%;
left: 13px;
}
h2.fq {
  margin-top: -110px;
  }
.bx1 h2{
width:100%;
left: 13px;
padding-top: 60px;
}

}

@media screen and (min-width: 375px) {


.download-top {
    width: 153px;
   margin: 40px 70px 20px 70px;
   
  }
  
  .service-page .prt1 {
    font-size: 16px;
    left: 30px;
}
  .prt{
    font-size: 17px!important;
  }
  .sign-box{
  bottom:0!important;   
  margin-bottom: 10px;
}
 .video-left p{
    width:100%;
  }
  .service-detail-get .gtm-txt a {
    padding: 14px 9px;
    font-size: 17px!important;   
}
.gtm-txt a {
    padding: 14px 14px;
}
.service-page .gtm-txt a {
    padding: 14px 10px;
}
}

  @media screen and (max-width: 360px) {


.service-print-detail .print-section {
    
    margin: 0px 0px;
}
.bt {
    margin-bottom: 0px;
    display: inline-block;
    width: 100%;
}
.service-page .prt1 {
    font-size: 16px;
    left: 30px;
}
ul.print-list li {
    margin: 10px 20px;
}
.log-ico .icon_cont {
    width: 50%;
    }
.service-detail-get .gtm-txt a {
    padding: 14px 9px;
    font-size: 16px;
}
.txt h2 {
    font-size: 16px;
    }
.prt1 {
    font-size: 17px!important;
    
}
.service-page .gtm-txt a {
    
    padding: 14px 10px;
}
.printing-services h2 {
    font-size: 40px;
    }
.gtm-txt a {
    padding: 14px 13px;
    font-size: 17px;
}
ul.print-list{
  display: flex;
}
.vid-btn a {
    font-size: 17px;
    }
.sign-box{
  bottom:0!important;
  margin-bottom: 10px;
}
.service-detail-test {
    
    margin-bottom: 50px;
}
.ftx p {
    width: 75%;
    }
 .download-top {
    width: 153px!important;
   margin: 40px 70px 20px 70px!important;
   
  }
  .right-gle h2 {
    font-size: 35px;
  }
  .service-page .printing-services p {
    width: 96%;
  }
  .des1 {
    left: 68px!important;
    
}
.left-wrk h2 {
    font-size: 33px;
}
  .video-left p{
    width:100%;
  }

.wt1 {
    font-size: 12px;
}

  
  .inside-content {
    position: relative;
    top: 0px;
    margin-bottom: 0px;
    display: inline-block;
}

.card {
    padding: 25px;
    
}
.MuiGrid-container {
    display: inline-block!important;
    }
.right-gle p {
    
    width: 100%;
    }
.social img {
    width: 10%;
   
}
.container-fluid bx1 {
    margin-top: 680px!important;
}
.download-top {
    width: 153px!important;
   margin: 40px 90px 20px 90px!important;
   
  }
  .video-left p{
    width:100%;
  }

  .printing-services p {
    width: 90%;
    
}
.bx1 h2{
width:100%;
left: 13px;
}
}



@media screen and (max-width: 320px) {
.navMobileButton {
    display: flex;
}
.cokee-privacy p {
    font-size: 12px!important;
}
p.cokee-quote {
  margin: 2px;
  text-align: justify;
}
.bt {
    
    margin-bottom: 0px;
    display: inline-block;
    width: 100%;
}
.service-page .prt1 {
    font-size: 15px;
    left: 30px;
}
ul.print-list li {
    margin: 10px 14px;
}
.log-num .MuiOutlinedInput-root {
    width: 75%!important;
}
.login_number_cont {
    width: 74%!important;
    display: flex;
    align-items: center;
    flex-direction: row;
}
.log-num {
    width: 100%;
}
.prt1 {
    font-size: 16px!important;
    
}

.sign-box{
  bottom:0!important;
  margin-bottom: 10px;
}
.cardx p {
    font-size: 18px;
    }
.service-detail-test .des1 {
    left: 54px!important;
}
.print-section h2 {
    font-size: 30px;
    }
.vid-btn a {
    font-size: 15px;
    }
    .right-gle h2 {
    font-size: 30px;
  }
.video-left h2 {
    font-size: 27px;
}
.log-ico {
    width: 90%!important;
    margin: 0px 0px!important;
    position: relative;
    bottom: 50px;
}
ul.print-list {
    
    display: flex;
}
.digital {
    margin: 50px 0px 0px 0px;
    
}
.left-side {
    padding: 60px 10px 150px!important;
}
.bg-auth .auth-btn{
  font-size: 13px!important;
}
.txt h2 {
    font-size: 17px;
  }
.top-navi-btn .download-top {
    width: 245px!important;
    
}
.sign-top {
    position: relative;
    right: 0px;
    
}
.drop-menux {
   display: block;
    text-align: left;
    margin: 0px 8px 0px 8px;
}
.service-detail-top .header-line {
    font-size: 20px;   
}
p.arrow span {
   
    position: relative;
    right: 165px;
    top:5px;
    
}
.service-detail-get .gt-txt h2 {
    font-size: 23px;
}
.service-detail-get .gt-txt p {
    width: 100%!important;
    position: relative;
    right: 0px;
}
.service-page .printing-services p {
    width: 96%;
  }
  .service-detail-get .gtm-txt a {
    padding: 14px 9px;
    font-size: 12px;
}

 .download-top {
    width: 153px!important;
   margin: 40px 70px 20px 70px!important;
   
  }
  .gt-txt h2 {
    font-size: 24px;
    
}
.printing-services h2 {
    font-size: 30px;
   
}
.gt-txt p {
   
    font-size: 19px;
}
  .testimonial-inner-content h2 {
    font-size: 33px;
}
  .bx1 {
    margin-top: 980px;
}
.bx1 h2{
  margin-top:65px;
  }
  .gtm-txt a {
    padding: 14px 10px;
    font-size: 15px;

}
.left-wrk h2 {
    font-size: 30px;
    }
.inside-content h2 {
    font-size: 40px;
    }
.service-page .gtm-txt a {
   
    padding: 14px 8px;
    font-size: 14px;
}
  ul.print-list span {
    font-size: 15px!important;
}
  .vid-btn {
    width: 54%;
}
  .des1 {
    left: 60px!important;
    width:70%;
    
}
  .video-left p{
    width:100%;
  }

.wt1 {
    font-size: 10px;
}

  
  .inside-content {
    position: relative;
    top: 0px;
    margin-bottom: 0px;
    display: inline-block;
}

.card {
    padding: 25px;
    
}
.MuiGrid-container {
    display: inline-block!important;
    }
.right-gle p {
    
    width: 100%;
    }
.social img {
    width: 10%;
   
}
.container-fluid bx1 {
    margin-top: 680px!important;
}
.download-top {
    width: 153px!important;
   margin: 40px 90px 20px 90px!important;
   
  }
  .video-left p{
    width:100%;
  }

  .printing-services p {
    width: 90%;
    
}
.bx1 h2{
width:100%;
left: 13px;
}
}