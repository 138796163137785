.logoCont {
    width:100%;
    padding-top: 2%;
    display:flex;
    justify-content:center;
    align-items: center;
    /*margin-bottom:100px*/
}
.dashBoard_sidebar {
    background-color: #fdd001;
    height: 100vh;
    width: 20%;
    position: fixed;
    top: 0;
    float: left;
    /*left: 0;*/
    box-shadow: 0 16px 38px -12px rgba(0,0,0,.16), 0 4px 25px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(0,0,0,.2);
}
.dashBoard_content{
    float: right;
    min-height: 100vh;
    width: 80%;
    background-image: url("../../assets/bg2.jpg") !important;
    /* background-color: #f7f7f7; */
}
.logOutCont {
     width:100%;
     display:flex;
    margin-top: 80px;
     justify-content:center;
     /*margin-bottom:100px*/
     cursor: pointer;
 }
.companyLogo{
    width:130px;
    object-fit: contain;
}
.navLink_cont
{
    display: flex;
    flex-direction: column;
    margin-top: 60px !important;
    /* margin-left: 40px !important; */
    /*align-items: center;*/
}
.menuIcon
{
    width:25px;
    height: 25px;
    display: none;
    object-fit: contain;
}
.logoNavCont{
    /*background: #fdd001;*/
    height: 100px;
    display:none;
    align-items: center;
    padding: 10px 25px;
    justify-content: flex-end;
}
@media screen and (max-width: 800px){
    .logoCont {
        padding: 10px 20px;
        justify-content:space-between;
    }
    .logoNavCont{
        display: flex;
    }
    .dashBoard_sidebar{
        width: 45%;
        z-index: 20000;
        min-width: 270px;
        transform: translateX(105%);
        transition: transform 0.3s ease-in;
        position: absolute !important;
        right: 0;
        top: 0;
    }
    /*.sideBar_content_cont{*/
    /*    float: right;*/
    /*    width: 270px !important;*/
    /*    background:#fdd001 ;*/
    /*    height: 100vh;*/
    /*}*/
    .dashBoard_content{
        width: 100%;
    }
    .menuIcon
    {
        display: block;
    }
}
@media screen and (max-width: 320px){
.navLink_cont
{
    display: flex;
    flex-direction: column;
    margin-top: 60px !important;
    margin-left: 40px !important;
    /*align-items: center;*/
}
}
@media screen and (max-width: 480px) {
.navLink_cont
{
    display: flex;
    flex-direction: column;
    margin-top: 60px !important;
    margin-left: 40px !important;
    /*align-items: center;*/
}
}
@media screen and (max-width: 600px) {
    .navLink_cont
    {
        display: flex;
        flex-direction: column;
        margin-top: 60px !important;
        margin-left: 40px !important;
        /*align-items: center;*/
    }
    }
.header_open_cont
{
    transform: translateX(0%);
    /*height: auto !important;*/
}

.navBarListTextItem
{
    font-size: 14px;
    color: #1e1f20;
    margin-left: 7px;

}